import React, { Component, createRef } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";

import { Select, ErrorMessage, showAlertMessage, CustomModal } from "../../../../resusableComponents/common/formElements";

import { updateLoadingState } from "../../../../redux/actions/application";
import { doGetAssetTypeList, doGetTopicList, doGetSubTopicList, createNewAsset, uploadSingleAsset } from "../../../../redux/services/campaignSetup";

const dropzoneRef = createRef();

class UploadNewAsset extends Component {

  constructor(props) {
    super(props);

    this.defaultAssetState = {
      clientId: _get(props, "clientId", null),
      name: "",
      assetCode: "",
      url: "",
      azureStorageURL: "",
      fileName: "",
      topic: null,
      subTopic: null,
      typeId: null,
    }

    this.state = {
      newAsset: _cloneDeep(this.defaultAssetState),
      errors: {},
      uploadErrors: {},
      showModal: false,
      assetTypeList: [],
      topicList: [],
      subTopicList: [],
      isFormSubmitted: false
    };
  }

  componentDidUpdate(prevProps, prevStates) {
    if (!_isEqual(prevStates.newAsset, this.state.newAsset) && (_get(this.state, "isFormSubmitted", false) === true)) {
      this._handleValidation();
    }

    if (!_isEqual(prevStates.showModal, this.state.showModal) && (_get(this.state, "showModal", false) === true)) {
      this._getAssetTypeList();
      this._getTopicList();
    }

    if ((!_isEqual(_get(prevStates, "newAsset.topic", null), _get(this.state, "newAsset.topic", null))) && (_get(this.state, "newAsset.topic", null) !== null)) {
      this._getSubTopicList();
    }
  }

  _getAssetTypeList = async () => {
    const { updateLoadingState } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetAssetTypeList();

      if (_get(response, "flag", false) === true) {

        this.setState({ assetTypeList: _get(response, "data", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching topic list.")));
        this.setState({ assetTypeList: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching topic list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _getTopicList = async () => {
    const { updateLoadingState } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetTopicList();

      if (_get(response, "flag", false) === true) {

        this.setState({ topicList: _get(response, "data", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching topic list.")));
        this.setState({ topicList: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching topic list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _getSubTopicList = async () => {
    const { updateLoadingState } = this.props;
    const { newAsset: { topic } } = this.state;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetSubTopicList(_get(topic, "id", 0));

      if (_get(response, "flag", false) === true) {
        this.setState({ subTopicList: _get(response, "data", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching sub topic list.")));
        this.setState({ subTopicList: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching sub topic list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _onDrop = async (acceptedFiles) => {
    const { updateLoadingState } = this.props;

    if (typeof updateLoadingState === "function") updateLoadingState(true);

    const firstFile = acceptedFiles[0];

    let data = new FormData();

    data.append("formfile", firstFile);
    // data.append("campaign_id", campaignId);

    try {

      const response = await uploadSingleAsset(data);

      if (_get(response, "flag", false) === true) {

        this.setState((prevState) => ({
          showModal: true,
          newAsset: {
            ...prevState.newAsset,
            url: _get(response, "data.url", ""),
            azureStorageURL: _get(response, "data.url", ""),
            fileName: _get(response, "data.filename", ""),
          }
        }));
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while uploading assets."));
      }
    } catch (err) {
      showAlertMessage((err.message || "Something went wrong while uploading on server."));

    } finally {

      if (typeof updateLoadingState === "function") updateLoadingState(false);
    }
  }

  _handleChange = (e, name) => {
    e.preventDefault();

    const { value } = (e.target || {});

    this.setState((prevState) => ({
      newAsset: {
        ...prevState.newAsset,
        [name]: value
      }
    }));
  }

  _handleValidation(returnFlag = false) {
    const { intl } = this.props;
    const { newAsset } = this.state;
    let errors = {};

    if (_isEmpty(newAsset.typeId)) {
      errors["typeId"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.assets.create.label_asset_type", defaultMessage: "asset type" })
      });
    }
    if (_isEmpty(newAsset.name)) {
      errors["name"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.assets.create.label_name", defaultMessage: "name" })
      });
    }
    if (_isEmpty(newAsset.topic)) {
      errors["topic"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.assets.create.label_topics", defaultMessage: "topics" })
      });
    }
    if (_isEmpty(newAsset.subTopic)) {
      errors["subTopic"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.assets.create.label_sub_topics", defaultMessage: "sub topics" })
      });
    }
    if (_isEmpty(newAsset.assetCode)) {
      errors["assetCode"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.assets.create.label_asset_code", defaultMessage: "asset code" })
      });
    }

    if (_isEmpty(newAsset.url)) {
      errors["url"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.assets.create.label_url", defaultMessage: "url" })
      });
    }

    this.setState({ errors });

    if (returnFlag) {
      return (!_isEmpty(errors)) ? false : true;
    }
  }

  _handleCreateNewAsset = async () => {
    const { clientId, formData, setState, updateLoadingState, intl } = this.props;
    const { newAsset, errors } = this.state;

    if (!_isEmpty(errors)) {
      showAlertMessage(intl.formatMessage({ id: "error.please_fill_up_the_proper_details", defaultMessage: "Please fill up the proper details." }));
      return false;
    }

    this.setState({ isFormSubmitted: true });

    const isValidForm = this._handleValidation(true);

    if (!isValidForm) {
      showAlertMessage(intl.formatMessage({ id: "error.please_fill_up_the_proper_details", defaultMessage: "Please fill up the proper details." }));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        client_id: (clientId || null),
        asset_name: (newAsset.name || ""),
        asset_code: (newAsset.assetCode || ""),
        asset_url: (newAsset.url || ""),
        topic_id: _get(newAsset, "topic.id", null),
        sub_topic_id: _get(newAsset, "subTopic.id", null),
        type_id: _get(newAsset, "typeId.id", null)
      };

      const response = await createNewAsset(payload);

      if (_get(response, "flag", null) === true) {

        showAlertMessage(_get(response, "message", "Asset created successfully."), "success");

        this._resetCreateAssetForm();

        if (typeof setState === "function") {
          setState({
            selectedOption: "useExisting",
            formData: { ...formData, searchAssets: [{ id: _get(response, "data.asset_id", null), asset_name: _get(response, "data.asset_name", "") }] }
          });
        }
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while creating new asset."));
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while creating new asset.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _resetCreateAssetForm = () => {
    this.setState({
      newAsset: _cloneDeep(this.defaultAssetState),
      errors: {},
      uploadErrors: {},
      showModal: false,
      isFormSubmitted: false
    })
  }

  _renderAddAssetModal() {
    const { intl } = this.props;
    const { topicList, subTopicList, assetTypeList, showModal, newAsset, errors } = this.state;

    return (
      <CustomModal
        size="lg"
        isOpen={showModal}
        className="addContentModal"
        modalTitle={(intl.formatMessage({ id: "campaign_setup.assets.create.title", defaultMessage: "create asset" }))}
        onHide={() => this._resetCreateAssetForm()}
        onClose={() => this._resetCreateAssetForm()}
      >
        <>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.create.label_asset_type" defaultMessage="asset type" />
                  <span className="text-danger">*</span>
                </label>
                <Select
                  className="form-custom-select"
                  placeholder={intl.formatMessage({ id: "placeholder.select_field", defaultMessage: "Select {field}" },
                    { field: intl.formatMessage({ id: "campaign_setup.assets.create.label_asset_type", defaultMessage: "asset type" }) })}
                  value={(newAsset.typeId || null)}
                  options={(assetTypeList || [])}
                  error={(errors.typeId || "")}
                  getOptionValue={(option) => (option.id)}
                  getOptionLabel={(option) => (option.asset_type)}
                  onChange={(selectedOptions) => this.setState((prevState) => ({ newAsset: { ...prevState.newAsset, typeId: selectedOptions } }))}
                  isMulti={false}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.create.label_topics" defaultMessage="topics" />
                  <span className="text-danger">*</span>
                </label>
                <Select
                  placeholder={intl.formatMessage({ id: "placeholder.select_field", defaultMessage: "Select {field}" },
                    { field: intl.formatMessage({ id: "campaign_setup.assets.create.label_topics", defaultMessage: "topics" }) })}
                  value={(newAsset.topic || null)}
                  options={(topicList || [])}
                  error={(errors.topic || "")}
                  getOptionValue={(option) => (option.id)}
                  getOptionLabel={(option) => (option.topic)}
                  onChange={(selectedOptions) => this.setState((prevState) => ({ newAsset: { ...prevState.newAsset, topic: selectedOptions } }))}
                  isMulti={false}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.create.label_sub_topics" defaultMessage="sub topics" />
                  <span className="text-danger">*</span>
                </label>
                <Select
                  className="form-custom-select"
                  placeholder={intl.formatMessage({ id: "placeholder.select_field", defaultMessage: "Select {field}" },
                    { field: intl.formatMessage({ id: "campaign_setup.assets.create.label_sub_topics", defaultMessage: "sub topics" }) })}
                  value={(newAsset.subTopic || null)}
                  options={(subTopicList || [])}
                  error={(errors.subTopic || "")}
                  getOptionValue={(option) => (option.id)}
                  getOptionLabel={(option) => (option.sub_topic)}
                  onChange={(selectedOptions) => this.setState((prevState) => ({ newAsset: { ...prevState.newAsset, subTopic: selectedOptions } }))}
                  isMulti={false}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.create.label_name" defaultMessage="name" />
                  <span className="text-danger">*</span>
                </label>
                <input
                  id="create_name"
                  name="create_name"
                  className="form-control"
                  defaultValue={(newAsset.name || "")}
                  placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                    field: intl.formatMessage({ id: "campaign_setup.assets.create.label_name", defaultMessage: "name" })
                  })}
                  onChange={(e) => this._handleChange(e, "name")}
                />
                {(errors.name !== "") && (<ErrorMessage message={(errors.name || "")} />)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.create.label_asset_code" defaultMessage="asset code" />
                  <span className="text-danger">*</span>
                </label>
                <input
                  id="create_code"
                  name="create_code"
                  className="form-control"
                  defaultValue={(newAsset.assetCode || "")}
                  placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                    field: intl.formatMessage({ id: "campaign_setup.assets.create.label_asset_code", defaultMessage: "asset code" })
                  })}
                  onChange={(e) => this._handleChange(e, "assetCode")}
                />
                {(errors.assetCode !== "") && (<ErrorMessage message={(errors.assetCode || "")} />)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.create.label_url" defaultMessage="url" />
                  <span className="span-link m-l-5"><FormattedMessage id="campaign_setup.assets.create.label_edit_url" defaultMessage="(edit)" /> </span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  id="create_url"
                  name="create_url"
                  className="form-control"
                  defaultValue={(newAsset.url || "")}
                  placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                    field: intl.formatMessage({ id: "campaign_setup.assets.create.label_url", defaultMessage: "url" })
                  })}
                  onChange={(e) => this._handleChange(e, "url")}
                />
                {(errors.name !== "") && (<ErrorMessage message={(errors.url || "")} />)}
              </div>
            </div>
          </div>

          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button className="btn btn-secondary text-capitalize" onClick={() => this._resetCreateAssetForm()} >
              <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
            </button>
            <button className="btn btn-primary text-capitalize" onClick={() => this._handleCreateNewAsset()} >
              <FormattedMessage id="btn.submit" defaultMessage="Submit" />
            </button>
          </div>
        </>
      </CustomModal >
    );
  }

  render() {
    const { allowedAssetFiles, allowedAssetFilesLabel } = this.props;
    const { showModal } = this.state;

    return (
      <>
        {(showModal === true) ? this._renderAddAssetModal() : (
          <Dropzone
            maxFiles={1}
            multiple={false}
            accept={allowedAssetFiles}
            onDrop={(f) => this._onDrop(f)}
            ref={dropzoneRef}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div className="mb-6" {...getRootProps({ className: "dropzone" })}>
                <div>
                  <label className="form-label text-capitalize">
                    <FormattedMessage id="file.file_upload" defaultMessage="file upload" />
                  </label>
                  <div className="dropzone-input form-control">
                    <span>Browse...</span>
                    No file selected.
                  </div>
                  <input {...getInputProps()} />
                </div>
                <label htmlFor="singleUpload" className="mt-2 text-grey-93">
                  <FormattedMessage
                    id="file.allowed_files"
                    defaultMessage="Allowed file extensions : {allowedExtensions}"
                    values={{ allowedExtensions: allowedAssetFilesLabel }}
                  />
                </label>
              </div>
            )}
          </Dropzone>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  clientId: _get(state, "campaignSetup.summary.common.clientId", null),
  campaignId: _get(state, "campaignSetup.summary.common.campaignId", null),
  allowedAssetFiles: _get(state, "application.constants.allowedAssetFiles", ""),
  allowedAssetFilesLabel: _get(state, "application.constants.allowedAssetFilesLabel", ""),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UploadNewAsset));
