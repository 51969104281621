import React, { Component, Fragment, createRef } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty, map as _map } from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";

import { CustomModal, Select, ConfirmBox, showAlertMessage } from "../formElements";
import { withRouter } from "../../hoc/withRouter";

import { uploadReadFile } from "../../../redux/services/campaignSetup";

import { uploadReadFileSuccess, uploadReadFileError, removeUploadedFile, resetRemoveUploadedFile, saveUploadMappingData, resetDataMappingError, resetDataMappingConfig } from "../../../redux/actions/campaignSetup";
import { updateLoadingState } from "../../../redux/actions/application";

const dropzoneRef = createRef();

class SingleDropzone extends Component {

  static defaultProps = {
    displayUploadedFiles: false,
    displayUploadedRemoveBtn: false,
    preserveSelectedFile: false,
    uploadedFileName: "",
    uploadedFileURL: "",
    lastInsertedId: null,
    headerComponent: null
  };

  constructor(props) {
    super(props);

    this.hideAutoMappingFiles = ["none"];

    this.state = {
      mappingData: [],
      selectedFile: {},
      progress: 0,
      fileName: "",
      fileUrl: "",
      errorMessage: "",
      thirdPartyErrors: [],
      otherErrors: [],
      successMessage: ""
    };

  }

  componentDidMount() {
    const { uploadedFileName, uploadedFileURL } = this.props;

    let updateState = {}

    if (!_isEmpty(uploadedFileName)) { updateState = { selectedFile: { name: uploadedFileName } }; }

    if (!_isEmpty(uploadedFileURL)) { updateState = { ...updateState, fileUrl: uploadedFileURL }; }

    if (!_isEmpty(updateState)) { this.setState(updateState); }
  }

  componentDidUpdate(prevProps, prevStates) {

    if (!_isEqual(prevProps.mappingData, this.props.mappingData)) {
      if (!_isEmpty(this.props.mappingData)) {
        this.setState({
          mappingData: this.props.mappingData,
          fileName: _get(this.props, "mappingData.uploaded_file.filename", ""),
          fileUrl: _get(this.props, "mappingData.uploaded_file.url", ""),
        });
      } else {

        let updatedState = { mappingData: [], fileName: "", fileUrl: "" };

        if ((this.props.preserveSelectedFile || false) === false) {
          updatedState = { ...updatedState, selectedFile: {}, progress: 1 };
        }

        if (_isEmpty(updatedState)) { this.setState(updatedState); }
      }
    }

    if (!_isEqual(prevProps.uploadedFileName, this.props.uploadedFileName) && !_isEmpty(this.props.uploadedFileName)) {
      this.setState({ selectedFile: { name: this.props.uploadedFileName }, progress: 100 });
    }

    if (!_isEqual(prevProps.uploadedFileURL, this.props.uploadedFileURL) && !_isEmpty(this.props.uploadedFileURL)) {
      this.setState({ fileUrl: this.props.uploadedFileURL });
    }

    if (!_isEqual(prevProps.successMessage, this.props.successMessage) && !_isEmpty(this.props.successMessage)) {

      this.setState({ successMessage: _get(this.props, "successMessage", "") });

      if (typeof this.props.resetDataMappingError === "function") { this.props.resetDataMappingError(); }
    }

    if (!_isEqual(prevProps.errors, this.props.errors) && !_isEmpty(this.props.errors)) {
      this._handleErrors();
    }

    if (!_isEqual(prevProps.removeUpload, this.props.removeUpload) && !_isEmpty(this.props.removeUpload)) {
      const { removeUpload, resetRemoveUploadedFile, intl } = this.props;

      if (_get(removeUpload, "status", null) === null) {
        return false;
      }

      if (_get(removeUpload, "status", false) === true) {
        // Reset parent components state
        this.setState({ selectedFile: {}, fileName: "", fileUrl: "", progress: 1 }, () => this._confirmUplodation(true));

        showAlertMessage((removeUpload.message || intl.formatMessage({ id: "file.removed", defaultMessage: "File removed successfully." })), "success");
      } else {
        showAlertMessage((removeUpload.message || intl.formatMessage({ id: "file.removed_error", defaultMessage: "Something went wrong while removing file." })));
      }

      if (typeof resetRemoveUploadedFile === "function") { resetRemoveUploadedFile(); }
    }
  }

  _handleErrors = () => {
    const { errors, resetDataMappingError } = this.props;

    const state = {};
    const thirdPartyErrors = _get(errors, "errors.third_party_errors", []);
    const otherErrors = _get(errors, "errors.other_errors", []);
    let displayErrorMessage = true;

    if (!_isEmpty(thirdPartyErrors || [])) {
      state.thirdPartyErrors = _cloneDeep(thirdPartyErrors);
      // displayErrorMessage = false;
    } else if (!_isEmpty(otherErrors || [])) {
      state.otherErrors = _cloneDeep(otherErrors);
    }

    if (displayErrorMessage === true) { state.errorMessage = _get(errors, "message", ""); }

    if (!_isEmpty(state)) { this.setState(state); }

    if (typeof resetDataMappingError === "function") { resetDataMappingError(); }
  }

  _onDrop = async (acceptedFiles, fileRejections) => {
    const { intl, campaignId = null, source = "", displayUploadedFiles = false, uploadReadFileSuccess, uploadReadFileError, updateLoadingState } = this.props;

    if (_isEmpty(acceptedFiles)) {
      showAlertMessage(intl.formatMessage({ id: "error.select_valid", defaultMessage: "Please select valid {field}." }, {
        field: intl.formatMessage({ id: "file", defaultMessage: "file" })
      }));

      return false;
    }

    const selectedFile = acceptedFiles[0];

    if (displayUploadedFiles === true) {
      this.setState({ selectedFile, progress: 1 });
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      let data = new FormData();
      data.append("formfile", selectedFile);

      const variables = { campaign_id: campaignId, source, data };

      const response = await uploadReadFile(variables, ({ progress = 0 }) => this.setState({ progress }));

      if (_get(response, "flag", false) === true) {
        const showModel = !(this.hideAutoMappingFiles || []).includes(source);

        if (typeof uploadReadFileSuccess === "function") {
          uploadReadFileSuccess({
            data: _get(response, "data", {}),
            showModel,
            message: _get(response, "message", "")
          });
        }
      } else {
        if (typeof uploadReadFileError === "function") {
          uploadReadFileError({
            message: _get(response, "message", "Something went wrong while uploading file."),
            errors: _get(response, "error", "")
          });
        }

        if (displayUploadedFiles === true) { this.setState({ selectedFile: {}, progress: 1 }); }
      }
    } catch (error) {
      if (typeof uploadReadFileError === "function") {
        uploadReadFileError({
          message: _get(error, "message", "Something went wrong while uploading file."),
          errors: (error || {})
        });
      }

      if (displayUploadedFiles === true) { this.setState({ selectedFile: {}, progress: 1 }); }
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleModalSubmit = () => {
    const { campaignId = null, clientId = null, source = "None", pageSource = null, recheckDataValidation = false, saveUploadMappingData, briefIds = [], existingSelectedIds = [], existingAssetIds = null, leadOwners = null, intl } = this.props;
    const { mappingData, fileName } = this.state;

    if (_isEmpty(fileName)) {
      showAlertMessage(intl.formatMessage({ id: "error.enter", defaultMessage: "Please enter {field}." }, {
        field: intl.formatMessage({ id: "file.file_name", defaultMessage: "file name" })
      }));
    }

    if (typeof saveUploadMappingData === "function") {
      let fileResult = _get(mappingData, "uploaded_file", {});

      const variable = {
        campaign_id: campaignId,
        source,
        payload: {
          uploaded_file_result: { ...fileResult, "filename": fileName },
          fuzzy_matched: (mappingData.fuzzy_matched_list || []),
          client_id: clientId,
          source: (pageSource || "None"),
          check_data_validation: (recheckDataValidation || false),
          existing_selected_ids: (source === "assets") ? _map(existingAssetIds, "id") : (existingSelectedIds || []),
          lead_owner: _get(leadOwners, "id", null),
          brief_ids: (briefIds || []),
        }
      };

      saveUploadMappingData(variable);
    }
  }

  _confirmUplodation = (resetParentPropsOnly = false) => {
    const { setState, source = "", success } = this.props;
    const { mappingData, fileName, fileUrl } = this.state;

    if (resetParentPropsOnly === false) { this.setState({ successMessage: "" }); }

    let parentState = {
      fileUploaded: true,
      uploadedFileName: (!_isEmpty(fileName) || resetParentPropsOnly === true) ? fileName : _get(mappingData, "uploaded_file.filename", ""),
      uploadedFileURL: (!_isEmpty(fileUrl) || resetParentPropsOnly === true) ? fileUrl : _get(mappingData, "uploaded_file.url", "")
    };

    if (source === "lead_upload_file") {
      parentState = { ...parentState, countryList: (success.country_list || []), addedDataViaAS: false, savedSearchId: null };
    }

    if (source === "assets") {
      parentState = { ...parentState, assetIds: _get(success, "assetIds", []) };
    }

    if (source === "suppression_list") {
      parentState = { ...parentState, lastInsertedId: _get(success, "suppression_id", null) };
    }

    if (source === "account_list") {
      parentState = { ...parentState, lastInsertedId: _get(success, "account_id", null) };
    }

    if (typeof setState === "function") { setState(parentState); }
  }

  _handleCloseMappingModal = () => {
    const { resetDataMappingConfig } = this.props;

    if (typeof resetDataMappingConfig === "function") { resetDataMappingConfig() }

    this._handleRemoveFile();
  }

  showDataMappingModal = () => {
    const { showModal = false, source, intl } = this.props;
    const { mappingData, fileName } = this.state;

    if (showModal === false) { return null; }

    const headerList = _cloneDeep(mappingData.header || []);
    const mappedColmValues = _cloneDeep(mappingData.mapped_columns_values || []);
    let fuzzyMatchedList = _cloneDeep(mappingData.fuzzy_matched_list || []);

    return (
      <CustomModal
        size="xl"
        isOpen={showModal}
        centered={false}
        bodyClassName="data-mapping-modal"
        baseClassName="custom-popup-css"
        modalTitle={intl.formatMessage({ id: "file.data_upload.column_relationship", defaultMessage: "column relationship" })}
        onHide={() => this._handleCloseMappingModal()}
        onClose={() => this._handleCloseMappingModal()}
      >
        <>
          <div className="row">
            <div className="col-lg-4">
              <label className="form-label text-capitalize">
                <FormattedMessage id="file.file_name" defaultMessage="file name" />
              </label>
              <input
                type="text"
                className="form-control"
                name="uploadFileName"
                defaultValue={(fileName || "")}
                onChange={(e) => this.setState({ fileName: _get(e, "target.value", "") })}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className={`table-responsive custom-scroll mt-4 table-${(source || "")}`}>
                <table className="table">
                  <thead className="table-light">
                    <tr>
                      {(headerList || []).map((h, i) => (<th key={`map-header-${i}`}>{(h || "")}</th>))}
                    </tr>
                    <tr>
                      {(fuzzyMatchedList || []).map((f, j) => {

                        return (
                          <th key={`map-dropdown-${j}`}>
                            <Select
                              className="form-custom-select"
                              placeholder={intl.formatMessage({ id: "placeholder.select_field", defaultMessage: "Select {field}" },
                                { field: intl.formatMessage({ id: "fields", defaultMessage: "fields" }) })}
                              value={(f.matched_list_Value || null)}
                              options={mappedColmValues}
                              isMulti={false}
                              getOptionValue={(option) => (option.key)}
                              getOptionLabel={(option) => (option.value)}
                              onChange={(e) => {
                                let tmp = fuzzyMatchedList;
                                tmp[j].matched_list_Value = e;

                                this.setState({ mappingData: { ...mappingData, fuzzy_matched_list: tmp } });
                              }}
                            />
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {(mappingData.sample_data || []).map((v, l) => {
                      return (
                        <tr key={`map-data-${l}`}>
                          {Object.values(v || []).map((w, k) => (<td key={`map-content-${k}`}>{(w || "")}</td>))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button className="btn btn-primary" onClick={() => this._handleModalSubmit()} >
              <FormattedMessage id="btn.submit" defaultMessage="submit" />
            </button>
            <button className="btn btn-secondary" onClick={() => this._handleCloseMappingModal()} >
              <FormattedMessage id="btn.cancel" defaultMessage="cancel" />
            </button>
          </div>
        </>
      </CustomModal>
    )
  }

  _handleCloseErrorDataModal = () => {
    this.setState({
      errorMessage: "",
      thirdPartyErrors: [],
      otherErrors: []
    });
  }

  _renderFailedDataModal = () => {
    const { intl } = this.props;
    const { thirdPartyErrors, otherErrors, errorMessage } = this.state;

    if (_isEmpty(thirdPartyErrors) && _isEmpty(otherErrors) && _isEmpty(errorMessage)) {
      return null;
    }

    const onlyErrorMessage = (_isEmpty(thirdPartyErrors) && _isEmpty(otherErrors));

    let modalTitle = intl.formatMessage({ id: "file.required_fields", defaultMessage: "required fields" });

    if (!_isEmpty(otherErrors)) {
      modalTitle = intl.formatMessage({ id: "file.invalid_values", defaultMessage: "invalid values" });
    }

    return (
      <CustomModal
        size={(onlyErrorMessage === true) ? "md" : "lg"}
        modalTitle={modalTitle}
        onHide={() => this._handleCloseErrorDataModal()}
        onClose={() => this._handleCloseErrorDataModal()}
        isOpen={true}
        centered={true}
      >
        <>
          <div className="error-data-modal mb-3">
            {(!_isEmpty(errorMessage)) && (
              <p className="fw-bold">{(errorMessage || "")}</p>
            )}

            {(onlyErrorMessage === false) && (
              <>
                {(thirdPartyErrors || []).map((object, index) => {
                  const email = _get(object, "data.email", "");
                  const reasons = _get(object, "reasons", {});

                  return (
                    <div key={index} className="row g-0 border p-2 mb-1 rounded">
                      <div className="col-sm-12 pb-2 text-capitalize fw-bold">
                        {!_isEmpty(email) ?
                          (<FormattedMessage id="file.email" defaultMessage="email:" />) :
                          (<FormattedMessage id="file.row" defaultMessage="row:" />)
                        }
                      </div>
                      <div className="col-sm-12 pb-2">
                        {(email || (index + 1))}
                      </div>
                      <div className="col-sm-12 pb-2 text-capitalize fw-bold">
                        <FormattedMessage id="file.reasons" defaultMessage="reasons:" />
                      </div>
                      {_map((reasons || {}), (reason, key) => (
                        <div key={key} className="col-sm-12 pb-2">
                          <FormattedMessage id="file.reason_item" defaultMessage="{key} : {reason}" values={{ key, reason }} />
                        </div>
                      ))}
                    </div>
                  );
                })}

                {(otherErrors || []).map((object, index) => (
                  <div key={index} className="row g-0 border p-2 mb-1 rounded">
                    <div className="col-sm-auto text-capitalize fw-bold">
                      {_get(object, "column", "")}
                    </div>
                    <div className="pl-1 col pb-2">
                      {_get(object, "values", "")}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button className="btn btn-primary" onClick={() => this._handleCloseErrorDataModal()} >
              <FormattedMessage id="btn.close" defaultMessage="close" />
            </button>
          </div>
        </>
      </CustomModal>
    );
  }

  _handleRemoveFile = (deleteFromDB = false) => {
    const { removeUploadedFile, source = "", campaignId = null, lastInsertedId = null } = this.props;
    const { fileUrl } = this.state;

    if (!fileUrl) { return false; }

    if (typeof removeUploadedFile === "function") {
      removeUploadedFile({
        source: source,
        file_url: fileUrl,
        campaign_id: campaignId,
        delete_record: (deleteFromDB || false),
        delete_record_id: (lastInsertedId || null)
      });
    }
  }

  render() {
    const { acceptedFormat = "image/*", allowedExtensions = "", displayUploadedFiles = false, displayUploadedRemoveBtn = false, headerComponent, source } = this.props;
    const { successMessage, selectedFile = {} } = this.state;

    return (
      <div className="position-relative" >
        {(displayUploadedFiles === false || _isEmpty(selectedFile || {})) && (
          <>
            {(headerComponent) && (headerComponent)}

            <Dropzone
              maxFiles={1}
              multiple={false}
              accept={acceptedFormat}
              onDrop={(f, fileRejections) => this._onDrop(f, fileRejections)}
              ref={dropzoneRef}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div className="mb-3" {...getRootProps({ className: "dropzone" })}>
                  {((source || "") === "lead_upload_file") && (
                    <div className="file-upload">
                      <input {...getInputProps()} />
                      <img src={require("../../../assets/icons/icon-upload.svg").default} className="img-fluid" alt="" />

                      <p><FormattedMessage id="file.no_file_selected" defaultMessage="Drop files here or click to upload" /></p>
                    </div>
                  )}

                  {((source || "") !== "lead_upload_file") && (
                    <Fragment>
                      <div>
                        <label className="form-label text-capitalize">
                          <FormattedMessage id="file.file_upload" defaultMessage="file upload" />
                        </label>
                        <div className="dropzone-input form-control">
                          <span><FormattedMessage id="file.browse" defaultMessage="Browse..." /></span>
                          <FormattedMessage id="file.no_file_selected" defaultMessage="No file selected." />
                        </div>
                        <input {...getInputProps()} />
                      </div>
                      <label htmlFor="singleUpload" className="mt-2 text-grey-93">
                        <FormattedMessage
                          id="file.allowed_files"
                          defaultMessage="Allowed file extensions : {allowedExtensions}"
                          values={{ allowedExtensions }}
                        />
                      </label>
                    </Fragment>
                  )}
                </div>
              )}
            </Dropzone>
          </>
        )}

        {(displayUploadedFiles === true && !_isEmpty(selectedFile || {})) && (
          <>
            <div className="form-control">
              <label>{(selectedFile.name || "")}</label>
            </div>
            {(displayUploadedRemoveBtn === true) && (
              <div className="cursor-pointer dropzone-clear" onClick={() => this._handleRemoveFile(true)}>
                <img src={require("../../../assets/icons/icon-close.svg").default} className="img-fluid" alt="img" />
              </div>
            )}
          </>
        )}

        {(!(this.hideAutoMappingFiles || []).includes(source)) && (
          <>
            {this.showDataMappingModal()}

            {this._renderFailedDataModal()}
          </>
        )}

        <ConfirmBox
          key="success-confirm-box"
          isOpen={(!_isEmpty(successMessage)) ? true : false}
          content={(successMessage || "")}
          onConfirm={() => this._confirmUplodation()}
          onClose={() => this._confirmUplodation()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  mappingData: _get(state, "campaignSetup.fileUpload.dataMapping.data", {}),
  success: _get(state, "campaignSetup.fileUpload.dataMapping.success", {}),
  successMessage: _get(state, "campaignSetup.fileUpload.dataMapping.successMessage", ""),
  showModal: _get(state, "campaignSetup.fileUpload.dataMapping.showModal", false),
  removeUpload: _get(state, "campaignSetup.removeUpload", {}),
  errors: _get(state, "campaignSetup.fileUpload.errors", {}),
});

const mapDispatchToProps = (dispatch) => ({
  uploadReadFileSuccess: (data) => dispatch(uploadReadFileSuccess(data)),
  uploadReadFileError: (data) => dispatch(uploadReadFileError(data)),
  saveUploadMappingData: (data) => dispatch(saveUploadMappingData(data)),
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  removeUploadedFile: (data) => dispatch(removeUploadedFile(data)),
  resetRemoveUploadedFile: () => dispatch(resetRemoveUploadedFile()),
  resetDataMappingError: () => dispatch(resetDataMappingError()),
  resetDataMappingConfig: () => dispatch(resetDataMappingConfig()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(SingleDropzone)));
